<template>
  <section
    v-if="isShowForm"
  >
    <v-form ref="file_upload">
      <v-row class="mb-4">
        <v-col cols="12" md="4" class="pl-0">
          <div style="height:45px;">
            <p class="caption text-center">{{$_strings.order.UPLOAD_TRAVEL_DOCUMENT}}</p>
          </div>
          <div class="img-box d-none">
            <img
              v-if="formUploadFoto[index].travelDocument.encodedImage"
              :src="formUploadFoto[index].travelDocument.encodedImage"
              alt="Surat Jalan"
            >
          </div>
          <v-file-input
            :loading="formUploadFoto[index].travelDocument.isLoading"
            :disabled="formUploadFoto[index].travelDocument.isLoading"
            class="file_input d-none"
            height="20"
            :clearable="false"
            @change="($event) => $emit('setFileInput', $event, 'travelDocument', index)"
            :ref="'travelDocument'+index"
            v-model="formFileInput[index]['travelDocument'].encodedImage"
            type="file"
            accept="image/*"
            outlined
            dense
            placeholder="Pilih Foto"
            prepend-icon=""
            :rules="formFileInput[index]['travelDocument'].isRequired ? [v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.UPLOAD_TRAVEL_DOCUMENT)] : []"
          >
            <template v-slot:selection="{ text }">
              <v-chip
                style="w-50"
                small
                label
                color="primary"
              >
                {{ text.length > 20 ? text.substr(0, 20) : text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn
            :loading="formUploadFoto[index].travelDocument.isLoading"
            :disabled="formUploadFoto[index].travelDocument.isLoading"
            color="blue-grey"
            small
            block
            class="ma-2 white--text"
            @click="$refs['travelDocument'+index].$refs.input.click()"
          >
            Pilih Gambar
            <v-icon
              right
              dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" class="pl-0">
          <div style="height:45px;">
            <p class="caption text-center">{{$_strings.order.UPLOAD_DOCUMENTATION}}</p>
          </div>
          <div class="img-box d-none">
            <img
              v-if="formUploadFoto[index].documentation.encodedImage"
              :src="formUploadFoto[index].documentation.encodedImage"
              alt="Dokumentasi"
            >
          </div>
          <v-file-input
            :loading="formUploadFoto[index].documentation.isLoading"
            :disabled="formUploadFoto[index].documentation.isLoading"
            class="file_input d-none"
            :clearable="false"
            @change="($event) => $emit('setFileInput', $event, 'documentation', index)"
            :ref="'documentation'+index"
            v-model="formFileInput[index]['documentation'].encodedImage"
            placeholder="Pilih Foto"
            type="file"
            accept="image/*"
            outlined
            dense
            prepend-icon=""
            :rules="formFileInput[index]['documentation'].isRequired ? [v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.UPLOAD_DOCUMENTATION)] : []"
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text.length > 20 ? text.substr(0, 20) : text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn
            :loading="formUploadFoto[index].documentation.isLoading"
            :disabled="formUploadFoto[index].documentation.isLoading"
            color="blue-grey"
            small
            block
            class="ma-2 white--text"
            @click="$refs['documentation'+index].$refs.input.click()"
          >
            Pilih Gambar
            <v-icon
              right
              dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" class="pl-0">
          <div style="height:45px;">
            <p class="caption text-center">{{$_strings.order.UPLOAD_ROAD_COMMAND}}</p>
          </div>
          <div class="img-box d-none">
            <img
              v-if="formUploadFoto[index].deliveryOrder.encodedImage"
              :src="formUploadFoto[index].deliveryOrder.encodedImage"
              alt="Surat Perintah Pengiriman"
            >
          </div>
          <v-file-input
            :loading="formUploadFoto[index].deliveryOrder.isLoading"
            :disabled="formUploadFoto[index].deliveryOrder.isLoading"
            class="file_input d-none"
            :clearable="false"
            @change="($event) => $emit('setFileInput', $event, 'deliveryOrder', index)"
            :ref="'deliveryOrder'+index"
            v-model="formFileInput[index]['deliveryOrder'].encodedImage"
            placeholder="Pilih Foto"
            type="file"
            accept="image/*"
            outlined
            dense
            prepend-icon=""
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text.length > 20 ? text.substr(0, 20) : text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn
            :loading="formUploadFoto[index].deliveryOrder.isLoading"
            :disabled="formUploadFoto[index].deliveryOrder.isLoading"
            color="blue-grey"
            small
            block
            class="ma-2 white--text"
            @click="$refs['deliveryOrder'+index].$refs.input.click()"
          >
            Pilih Gambar
            <v-icon
              right
              dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: () => 0,
    },
    currentStatus: {
      type: String,
      default: () => '',
    },
    isLumpSum: {
      type: Boolean,
      default: () => false,
    },
    isBast: {
      type: Boolean,
      default: () => false,
    },
    formUploadFoto: {
      type: Array,
      default: () => [],
    },
    formFileInput: {
      type: Array,
      default: () => [],
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    orderDetails: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isShowSaveContinueButton() {
      const { listShipments } = this.orderDetails;
      const { shipmentsTitle } = this.shipment;
      return (listShipments && !listShipments.includes(shipmentsTitle)) || !listShipments;
    },
    isShipmentBast() {
      const { listShipmentBast } = this.orderDetails;
      const { shipmentsTitle } = this.shipment;
      return listShipmentBast && listShipmentBast.includes(shipmentsTitle);
    },
    isShowBastButton() {
      const { listShipmentBast } = this.orderDetails;
      const { shipmentsTitle } = this.shipment;
      return (listShipmentBast && !listShipmentBast.includes(shipmentsTitle)) || !listShipmentBast;
    },
    isShowForm() {
      const key = this.isLumpSum && this.orderDetails && this.currentStatus === 'ON_PROCESS';
      const condition = (
        (this.orderDetails.isLumpSum
        && this.orderDetails.latestStatus.toLowerCase() !== 'ba_penerimaan_konfirmasi_shipper'
        && this.orderDetails.isCurrentSequenceArrived
        && this.isShowSaveContinueButton
        && !this.orderDetails.isCurrentSequenceFinish)
        || (this.isBast && this.isShowBastButton)
      ) && !this.isShipmentBast;
      const compareCondition = key && condition;
      return compareCondition;
    },
  },
};
</script>
