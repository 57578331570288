<template>
  <v-row
    v-if="isShow"
    class="mt-2"
    justify="end"
  >
    <v-col
      v-if="isVisibleBaspButton"
      cols="12"
      sm="6"
      md="4"
      class="d-flex justify-end"
    >
      <v-btn
        block
        color="red"
        small
        class="white--text"
        @click="$emit('setDialogBasp', shipment)"
      >
        {{$_strings.order.REPORT_DIFFERENCE}}
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="d-flex justify-end"
    >
      <v-btn
        v-if="isBast && isShowBastButton"
        block
        color="primary"
        class="no-text-transform"
        small
        @click="$emit('updateStatusCargoShipment', index, shipment, isBast)"
      >
        <span class="text-none">
          {{ $_strings.order.CREATE_BAST }}
        </span>
      </v-btn>
      <v-btn
        v-else-if="!isShipmentBast && isShowSaveContinueButton"
        block
        color="primary"
        class="no-text-transform"
        small
        @click="$emit('updateStatusCargoShipment', index, shipment, isBast)"
      >
        <span class="text-none">
          {{ $_strings.order.SAVE_AND_CONTINUE }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    currentStatus: {
      type: String,
      default: () => '',
    },
    index: {
      type: Number,
      default: () => 0,
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    isLumpSum: {
      type: Boolean,
      default: () => false,
    },
    isBast: {
      type: Boolean,
      default: () => false,
    },
    orderDetails: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isShow() {
      const key = (this.isLumpSum && this.orderDetails && this.currentStatus === 'ON_PROCESS');
      const condition = (
        (
          this.orderDetails.isLumpSum
          && this.orderDetails.latestStatus.toLowerCase() !== 'ba_penerimaan_konfirmasi_shipper'
        )
        && (
          (
            this.orderDetails.isCurrentSequenceArrived
            && !this.orderDetails.isCurrentSequenceFinish
          ) || this.isBast
        ));
      const result = key && condition;
      return result;
    },
    isShowSaveContinueButton() {
      const { listShipments } = this.orderDetails;
      const { shipmentsTitle } = this.shipment;
      return (listShipments && !listShipments.includes(shipmentsTitle)) || !listShipments;
    },
    isShowBastButton() {
      const { listShipmentBast } = this.orderDetails;
      const { shipmentsTitle } = this.shipment;
      return (listShipmentBast && !listShipmentBast.includes(shipmentsTitle)) || !listShipmentBast;
    },
    isShipmentBast() {
      const { listShipmentBast } = this.orderDetails;
      const { shipmentsTitle } = this.shipment;
      return listShipmentBast && listShipmentBast.includes(shipmentsTitle);
    },
    isVisibleBaspButton() {
      const result = this.isBast
          && !(
            this.orderDetails.latestStatus.toLowerCase() === 'ba_penerimaan_diterima_gudang'
            || this.orderDetails.latestStatus.toLowerCase() === 'ba_penerimaan_kembali_ke_gudang_asal'
            || this.orderDetails.latestStatus.toLowerCase() === 'ba_penerimaan_ganti_lokasi_tujuan'
            || this.orderDetails.latestStatus.toLowerCase() === 'barang_diterima_tidak_utuh'
          ) && this.isShowBastButton;
      return result;
    },
  },
};
</script>
